dmx.Component('datastore', {

    initialData: {
        data: []
    },

    attributes: {
        session: {
            type: Boolean,
            default: false
        },

        columns: {
            type: Object,
            default: {}
        }
    },

    methods: {
        insert: function(data) {
            this._insert(data);
            this._store();
        },

        update: function(filter, data) {
            this._update(filter, data);
            this._store();
        },

        upsert: function(filter, data) {
            var toUpdate = this._filter(filter);
            if (toUpdate.length) {
                this._update(filter, data);
            } else {
                this._insert(data);
            }
            this._store();
        },

        delete: function(filter) {
            this._delete(filter);
            this._store();
        },

        clear: function() {
            this.records = [];
            this.lastid = 0;
            this._store();
        },

        get: function(filter) {
            return this._filter(filter);
        }
    },

    events: {
        inserted: Event,
        updated: Event,
        deleted: Event
    },

    render: function(node) {
        this.store = window[this.props.session ? 'sessionStorage' : 'localStorage'];
        this.records = [];
        this.lastid = 0;

        this._read = this._read.bind(this);

        if (!this.props.session) {
            window.addEventListener('storage', this._read);
        }
        
        this._read();
    },

    update: function(props, fields) {
        if (fields.has('columns')) {
            this._setData();
        }
    },

    _read: function() {
        try {
            var stored = this.store.getItem('datastore_' + this.name);

            if (stored) {
                stored = JSON.parse(stored);
                if (stored.records) this.records = stored.records;
                if (stored.lastid) this.lastid = stored.lastid;
            }
        } catch(err) {
            console.warn('Error parsing datastore', err);
        }

        this._setData();
    },

    _filter: function(filter) {
        if (typeof filter == 'number') filter = { $id: filter };

        return this.records.filter((record) => {
            if (Array.isArray(filter)) {
                for (var i = 0; i < filter.length; i++) {
                    for (var prop in filter[i]) {
                        if (!filter[i].hasOwnProperty(prop)) continue;
                        if (record[prop] === filter[i][prop]) {
                            return true;
                        }
                    }
                }
            } else {
                for (var prop in filter) {
                    if (!filter.hasOwnProperty(prop)) continue;
                    if (record[prop] === filter[prop]) {
                        return true;
                    }
                }
            }

            return false;
        });
    },

    _parseData: function(data) {
        if (typeof data == 'object' && !Array.isArray(data)) {
            return true;
        }

        return false;
    },

    _mergeData: function(record, data) {
        var merged = Object.assign({}, record);
        
        for (var prop in data) {
            if (!data.hasOwnProperty(prop)) continue;

            var value = data[prop];

            if (this._isExpression(value)) {
                value = dmx.parse(value, new dmx.DataScope(record, this));
            }

            merged[prop] = value;
        }

        return merged;
    },

    _insert: function(data) {
        var result = { inserted: [], deleted: [] };

        if (Array.isArray(data)) {
            for (var i = 0; i < data.length; i++) {
                var record = this._mergeData({ $id: ++this.lastid }, data[i]);
                this.records.push(record);
                result.inserted.push(record);
            }
        } else {
            var record = this._mergeData({ $id: ++this.lastid }, data);
            this.records.push(record);
            result.inserted.push(record);
        }

        this.dispatchEvent('inserted', null, result);
    },

    _update: function(filter, data) {
        if (!this._parseData(data)) {
            console.warn('Invalid data!', data);
            return;
        }

        var result = { inserted: [], deleted: [] };
        
        this._filter(filter).forEach((record) => {
            var newRecord = this._mergeData(record, data);
            if (!dmx.equal(record, newRecord)) {
                result.deleted.push(dmx.clone(record));
                result.inserted.push(dmx.clone(newRecord));
                Object.assign(record, newRecord);
            }
        });

        this.dispatchEvent('updated', null, result);
    },

    _delete: function(filter) {
        if (typeof filter == 'number') filter = { $id: filter };

        var result = { inserted: [], deleted: [] };

        this.records = this.records.filter(function(record) {
            for (var prop in filter) {
                if (!filter.hasOwnProperty(prop)) continue;
                if (record[prop] === filter[prop]) {
                    result.deleted.push(dmx.clone(record));
                    return false;
                }
            }

            return true;
        });

        this.dispatchEvent('deleted', null, result);
    },

    _store: function() {
        var data = JSON.stringify({
            records: this.records,
            lastid: this.lastid
        });

        if (data !== this.store.getItem('datastore_' + this.name)) {
            this.store.setItem('datastore_' + this.name, data);
            this._setData();
        }
    },

    _setData: function() {
        if (typeof this.props.columns == 'object') {
            this.set('data', this.records.map((record, i) => {
                const result = dmx.clone(record);
                const scope = dmx.DataScope({
                    $value: record,
                    $index: i,
                    $key: i,
                    ...record
                });

                for (let column in this.props.columns) {
                    if (!this.props.columns.hasOwnProperty(prop)) continue;
                    let value = this.props.columns[column];
                    if (this._isExpression(value)) {
                        value = dmx.parse(value, scope);
                    }
                    result[column] = value;
                }

                return result;
            }));
        } else {
            this.set('data', this.records);
        }
    },

    _isExpression: function(value) {
        return typeof value == 'string' && value.includes('{{');
    }

});
